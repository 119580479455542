import React from "react";
import { withRouter } from "react-router";

import logo from "../res/logo.png";

import logo_instagram from "../res/icons/logo-instagram.png";
import logo_twitter from "../res/icons/logo-twitter.png";
import logo_facebook from "../res/icons/logo-facebook.png";

class Navbar extends React.Component {
    _mounted;

    constructor(props){
        super(props);
    }

    componentDidMount(){
        this._mounted = true;
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    render(){
        return <nav className="navbar navbar-expand-md bg-light navbar-light sticky-top">
  
        <a className="navbar-brand" href="#"><img src={logo} width="150px"/></a>
      
      
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
          <i className="material-icons align-middle">menu</i>
        </button>
      
     
        <div className="collapse navbar-collapse" id="collapsibleNavbar">
          <ul className="navbar-nav">
          <li className="nav-item">
              <a className="nav-link" href="#inicio">Inicio</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#quienes-somos">¿Quienes somos?</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#catalogo">Productos más vendidos</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contacto">Contáctanos</a>
            </li>

            <li className="nav-item">
              <a className="btn btn-danger ml-1" href="https://sanifer.com.ve/catalogo.php" target="_blank">Catálogo 2015</a>
            </li>

            <li className="nav-item">
              <a className="btn btn-danger ml-1" href="https://firebasestorage.googleapis.com/v0/b/sanifersystem-eb338.appspot.com/o/documentos%2FCatalogo%20Sanifer.pdf?alt=media&token=ca0d76cc-fe02-4a64-9778-4beea7685c97" target="_blank">Catálogo 2020</a>
            </li>

            <li className="nav-item">
              <button className="btn btn-primary ml-1" >Hacer venta</button>
            </li>
          </ul>

          <ul className="navbar-nav ml-auto">
            <li className="nav-item" >
            <a className="nav-link social-media">
              <button className="btn btn-sm" onClick={() => {
                window.open("https://instagram.com/distsanifer")
              }}>
              <embed src={logo_instagram} width={25}/>
              </button>
              </a>
            </li>

            <li className="nav-item">
            <a className="nav-link social-media">
              <button className="btn btn-sm" onClick={() => {
                window.open("https://twitter.com/@distsanifer");
              }}>
              <embed src={logo_twitter} width={25}/>
              </button>
              </a>
            </li>


            <li className="nav-item">
            <a className="nav-link social-media">
              <button className="btn btn-sm" onClick={() => {
                window.open("https://facebook.com/distsanifer");
              }}>
              <embed src={logo_facebook} width={25}/>
              </button>
              </a>
            </li>
        
          </ul>
        </div>
      </nav>
    }
}

export default withRouter(Navbar);