import React from "react";
import { withRouter } from "react-router";

import logo from "../res/logo.png";
import logo_instagram from "../res/icons/logo-instagram.png";
import logo_twitter from "../res/icons/logo-twitter.png";
import logo_facebook from "../res/icons/logo-facebook.png";

class Footer extends React.Component {
    _mounted;

    constructor(props){
        super(props);
    }

    componentDidMount(){
        this._mounted = true;
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    render(){
        return <div className="footer">

            <div className="body">
            <div className="row mx-0">
                <div className="col-sm-7">
                    <p className="mb-0">
                    Distribuidora Sanifer 
Av 2, Manzana 1, loc 3, Zona industrial del Este,<br/> Los Naranjos, Guarenas, Edo Miranda
<br/><br/>
Tel: 0212-3612412/3076231/3076233 <br/>
0412-2234539 <br/>
Email: saniferb2b@gmail.com <br/>
                    </p>
                </div>

                <div className="col-sm-5">
                    <img src={logo} width={200}/>

                 <div className="flex-left">
                    <button className="btn btn-lg social-media" onClick={() => {
                window.open("https://instagram.com/distsanifer")
              }}>
              <embed src={logo_instagram} width={35}/>
              </button>

              <button className="btn btn-lg ml-2 social-media" onClick={() => {
                window.open("https://twitter.com/@distsanifer");
              }}>
              <embed src={logo_twitter} width={35}/>
              </button>

              <button className="btn btn-lg ml-2 social-media" onClick={() => {
                window.open("https://facebook.com/distsanifer");
              }}>
              <embed src={logo_facebook} width={35}/>
              </button>
              </div>
                </div>
            </div>
            </div>

            <div className="bottom">
            <p className="text-center mb-0">2021 Todos los derechos reservados - Distribuidora Sanifer, C.A. RIF: J-30874992-3</p>
            </div>
        </div>
    }
}

export default withRouter(Footer)